/* organogram.css */
.my-label {
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    display: inline-block;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.my-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-node {
    margin: 20px;
}

.my-line {
    border-left: 2px solid black;
    height: 20px;
    margin-left: 50%;
}

.my-line-border {
    border-top: 2px solid black;
}