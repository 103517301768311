button.active {
    background-color: black !important;
    border: none;
    color: white;
}

.select-container {
    position: relative;
    width: 100%;
    /* Adjust the width as needed */
}

.card-height {
    width: 100%;
    background-color: white;
    border: none;
    padding: 15px;
    min-height: 150vh;
    /* Set a minimum height to maintain */
    border-radius: 10px;
    overflow: auto;
    /* Add this to allow scrolling if the content is too large */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.select-contain {
    padding: 7px;
    width: 100%;
    border: "1px solid #e8e9eb";

    outline: none;
    /* Adjust the width as needed */
}

/* Style the select dropdown */
.select-container select {
    width: 100%;
    padding: 7px;
    font-size: 13px;
    border: "1px solid #e8e9eb";
    border-radius: 5px;
    appearance: none;
    /* Remove default arrow in Firefox */
    -webkit-appearance: none;
    /* Remove default arrow in Chrome/Safari/Edge */
}

/* Style the arrow icon */
.select-container::after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 68%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}